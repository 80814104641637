import { formSubmission, formSubmissionFields } from './eventContracts';
import {
  SessionContextType,
  useSessionContext,
} from '~/contexts/SessionContext';
import segmentEvents from '~/config/segment-events';
import { useContext } from 'react';
import SiteContext, { SiteContextType } from '~/contexts/SiteContext';

interface FormSubmissionType {
  sessionContext: SessionContextType;
  formSubmissionData: formSubmissionFields;
  siteContext: SiteContextType;
}

const formSubmit = ({
  sessionContext,
  formSubmissionData,
  siteContext,
}: FormSubmissionType) => {
  const formSubmissionContract: formSubmission = {
    form_submission_json: JSON.stringify({
      form_submission: [
        { source: siteContext?.site?.path, ...formSubmissionData },
      ],
    }),
    page_session_id: sessionContext.pageSessionId,
    session_id: sessionContext.sessionId,
    form_type: formSubmissionData.form_type,
  };
  window.tracking.track(segmentEvents.FORM_SUBMITTED, formSubmissionContract);
};

const useFormSubmission = () => {
  const sessionContext = useSessionContext();
  const siteContext = useContext(SiteContext);
  return (formSubmissionData: formSubmissionFields) => {
    return formSubmit({ sessionContext, formSubmissionData, siteContext });
  };
};

export default useFormSubmission;
